import Home from '../pages/Home';
import Checkout from '../pages/Checkout';
import Campaign from '../pages/Campaign';
import Tracking from '../pages/Tracking';
import { findCampaigns, findCampaign } from '../services/api';
import Collection from '../pages/Collection';
import Faq from '../pages/Faq';
// route
export default [
  {
    name: 'faq',
    url: '/faq',
    component: Faq,
  },
  {
    name: 'home',
    url: '/',
    component: Home,
    fetchData: async (route) => {
      try {
        return await findCampaigns(route.subDomain, 1);
      } catch {
        return [];
      }
    },
  },
  {
    name: 'checkout',
    url: '/cart',
    component: Checkout,
  },
  {
    name: 'campaign',
    url: '/product/:slug',
    component: Campaign,
    fetchData: async (route) => {
      try {
        return await findCampaign(route.subDomain, route.params.slug);
      } catch {
        return null;
      }
    },
  },
  {
    name: 'collection',
    url: '/collection/:slug',
    component: Collection,
    fetchData: async (route) => {
      try {
        return {
          campaigns: await findCampaigns(route.subDomain, 1, route.params.slug),
        };
      } catch {
        return null;
      }
    },
  },
  {
    name: 'tracking',
    url: '/track/:code',
    component: Tracking,
  },
];
