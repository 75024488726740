import React from 'react';
import { useRouter } from './Router';

export default function Link(props) {
  const router = useRouter();
  function onClick(event) {
    // On MacOS metaKey is the "command" key for opening in a new tab
    // @todo see if it works on other OS (Windows & Linux)
    if (!event.metaKey) {
      event.preventDefault();
      event.stopPropagation();
      if (props.disabled) {
        return;
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      router.goTo(props.to, props.params);
    }
  }

  return (
    <a
      className={`${props.className || ''}${props.disabled ? ' disabled' : ''}`}
      style={props.style}
      href={router.getUrl(router.getRoute(props.to, props.params))}
      onClick={onClick}
    >
      {props.children}
    </a>
  );
}
