import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

import './guest.scss';
import { Link, useRouter } from '../hooks/router';
import { useBasket } from '../hooks/basket/Basket';
import { useStore } from '../hooks/store/Store';
import { formatter } from '../utils/NumberFormat';

function isDescendant(parents, child) {
  let res = false;
  parents.forEach(function (element) {
    let node = child;
    while (node != null) {
      if (node == element) {
        res = true;
      }
      node = node.parentNode;
    }
  });
  return res;
}
export default function GuestLayout({ children }) {
  const basket = useBasket();
  const router = useRouter();
  const store = useStore();
  const [showBasket, setShowBasket] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showCollections, setShowCollections] = useState(false);
  const [headerFixed, setHeaderFixed] = useState(false);

  useEffect(() => {
    setShowBasket(false);
    setShowMenu(false);
    setShowCollections(false);
  }, [router.route.params.slug]);

  useEffect(() => {
    const onAdded = () => {
      setShowBasket(true);
    };
    basket.addListener('onAdded', onAdded);

    const onScroll = () => {
      if (window.scrollY > 30) {
        setHeaderFixed(true);
      } else if (window.scrollY <= 30) {
        setHeaderFixed(false);
      }
    };
    window.addEventListener('scroll', onScroll);

    const onClick = (event) => {
      if (
        !isDescendant(document.querySelectorAll('.basket'), event.target) &&
        !isDescendant(document.querySelectorAll('.cta button'), event.target)
      ) {
        setShowBasket(false);
      }
      if (
        !isDescendant(document.querySelectorAll('.menu'), event.target) &&
        !isDescendant(document.querySelectorAll('.cta button'), event.target)
      ) {
        setShowMenu(false);
        setShowCollections(false);
      }
    };
    document.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('scroll', onScroll);
      document.removeEventListener('scroll', onClick);
      basket.removeListener('onAdded', onAdded);
    };
  }, []);

  function onShowBasket() {
    setShowBasket((prevState) => !prevState);
  }

  function removeFromBasket(product) {
    return (event) => {
      event.stopPropagation();
      basket.remove(product.id);
    };
  }

  return (
    <>
      <Helmet>
        <title>{store.get('store').name} • Produits</title>
        <meta name="description" content={store.get('store').description} />
        <link
          rel="shortcut icon"
          href={
            store.get('store').favicon
              ? store.get('store').favicon.url
              : 'https://wiltee.com/favicon.ico'
          }
        ></link>
        <link
          rel="apple-touch-icon"
          href={
            store.get('store').favicon
              ? store.get('store').favicon.url
              : 'https://wiltee.com/apple-touch-icon.png'
          }
        ></link>
      </Helmet>
      <div className={`guest-layout${headerFixed ? ' header-fixed' : ''}`}>
        <div className="flash-text">
          Paiement 100% sécurisé par carte bancaire.
        </div>
        <header>
          <div className="menu">
            <div
              className="cta"
              onClick={() => setShowMenu((prevShowMenu) => !prevShowMenu)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
              >
                <path d="M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z" />
                <path d="M492,76H20C8.954,76,0,84.954,0,96s8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,76,492,76z" />
                <path d="M492,396H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20    C512,404.954,503.046,396,492,396z" />
              </svg>
            </div>
            {showMenu && (
              <div className="popin">
                <div className="links">
                  <Link to="home" className="item">
                    Accueil
                  </Link>
                  {store.get('store').collections &&
                    store.get('store').collections.length > 0 && (
                      <div className="item list">
                        <div
                          className="cta"
                          onClick={() => setShowCollections((p) => !p)}
                        >
                          Collections
                        </div>
                        {showCollections && (
                          <div className="sub">
                            {store
                              .get('store')
                              .collections.map((collection) => (
                                <Link
                                  to="collection"
                                  params={{ slug: collection.slug }}
                                >
                                  {collection.name}
                                </Link>
                              ))}
                          </div>
                        )}
                      </div>
                    )}
                  {store.get('store').externalLink.url && (
                    <a
                      href={store.get('store').externalLink.url}
                      className="item"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {store.get('store').externalLink.label || 'Lien'}
                    </a>
                  )}
                  <Link to="faq" className="item">
                    FAQ
                  </Link>
                </div>
                {(store.get('store').facebookUrl ||
                  store.get('store').instagramUrl ||
                  store.get('store').twitterUrl) && (
                  <div className="socials">
                    {store.get('store').facebookUrl && (
                      <a
                        href={store.get('store').facebookUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://wiltee.com/assets/img/fb_header_hover.png"
                          alt="facebook"
                        />
                      </a>
                    )}
                    {store.get('store').instagramUrl && (
                      <a
                        href={store.get('store').instagramUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://wiltee.com/assets/img/insta_header_hover.png"
                          alt=""
                        />
                      </a>
                    )}
                    {store.get('store').twitterUrl && (
                      <a
                        href={store.get('store').twitterUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://wiltee.com/assets/img/twitter_header_hover.png"
                          alt=""
                        />
                      </a>
                    )}
                  </div>
                )}
                <a className="tel" href="tel://0140269174">
                  <img
                    src="https://wiltee.com/assets/img/header_phone.png"
                    alt=""
                  />
                  +33.1.40.26.91.74
                </a>
              </div>
            )}
          </div>
          <div className="logo">
            <Link to="home">
              <img src={store.get('store').logo.url} alt="" />
            </Link>
          </div>
          <div className="space" />
          <a className="tel" href="tel://0140269174">
            <img src="https://wiltee.com/assets/img/header_phone.png" alt="" />
            +33.1.40.26.91.74
          </a>
          <div className="basket" onClick={() => onShowBasket()}>
            <span className="label">Panier</span>
            <img
              className="icon"
              src="https://wiltee.com/assets/img/new_basket.png"
              alt=""
            />
            {showBasket && (
              <div
                className="popin"
                onClick={(event) => event.stopPropagation()}
              >
                {basket.basket.lines.length === 0 ? (
                  <div className="empty">Votre panier est vide !</div>
                ) : (
                  <>
                    <div className="lines">
                      {basket.basket.lines.map((line, cpt) => (
                        <div key={cpt} className="line">
                          <img src={line.product.image.url} alt="" />
                          <div className="text">
                            {line.quantity} x {line.product.name} •{' '}
                            {line.product.price} €
                            <br />
                            {line.color.libelle} • {line.size.libelle}
                          </div>
                          <div
                            className="remove"
                            onClick={removeFromBasket(line.product)}
                          >
                            X
                          </div>
                        </div>
                      ))}
                    </div>

                    <Link className="go" to="checkout">
                      Finaliser ma commande •{' '}
                      {store.get('store').id === 7916
                        ? formatter.format(
                            basket.getTotalTTC(false, false) / (1 + 20 / 100)
                          )
                        : basket.getTotalTTC(false, false)}
                      €
                    </Link>
                  </>
                )}
              </div>
            )}
            <div className="count">{basket.basket.lines.length}</div>
          </div>
        </header>
        <div className="corp">{children}</div>
        <footer>
          <div className="args">
            <div className="container">
              <div className="arg">
                <div className="img">
                  <img
                    src="https://wiltee.com/assets/img/footer_lock.png"
                    style={{ height: '50px' }}
                    alt=""
                  />
                </div>
                <div className="text">Paiements sécurisés garantis.</div>
              </div>
              <div className="arg">
                <div className="img">
                  <img
                    src="https://wiltee.com/assets/img/footer_france.png"
                    style={{ height: '45px' }}
                    alt=""
                  />
                </div>
                <div className="text">
                  Articles imprimés en france avec amour.
                </div>
              </div>
              <div className="arg">
                <div className="img">
                  <img
                    src="https://wiltee.com/assets/img/footer_letter.png"
                    style={{ height: '35px' }}
                    alt=""
                  />
                </div>
                <div className="text">Service client au top sav@wiltee.com</div>
              </div>
              <div className="arg">
                <div className="img">
                  <img
                    src="https://wiltee.com/assets/img/footer_return.png"
                    style={{ height: '45px' }}
                    alt=""
                  />
                </div>
                <div className="text">Retours faciles de vos commandes</div>
              </div>
            </div>
          </div>
          <div className="mentions">
            <div className="container">
              <div className="column">
                <div className="title">
                  Une question ? Bruno se tient à votre disposition.
                </div>
                <a href="mailto:sav@wiltee.com">sav@wiltee.com</a>
                {/* <Link to="tracking">Suivi commande</Link> */}
              </div>
              <div className="column full">
                <div className="title">CGU, CGV, Mentions légales</div>
                <a href="https://wiltee.com/cgu">
                  Conditions générales d'utilisation
                </a>
                <a href="https://wiltee.com/cgv">
                  Conditions générales de vente
                </a>
                <a href="https://wiltee.com/mentions-legales">
                  Mentions légales
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
