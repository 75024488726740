import React from 'react';
import { useAuth } from '../auth/Auth';
import { useRouter } from './Router';

export default function Route(props) {
  const auth = useAuth();
  const router = useRouter();
  const route = router.route;

  if (route === false) {
    // If false, router didn't has time to run componentDidMount
    // Waiting for it
    return null;
  }
  let renderedRoute = route;
  Array.from(Array(props.deep || 0)).forEach(() => {
    renderedRoute = renderedRoute.subRoute;
  });

  if (renderedRoute) {
    if (
      renderedRoute.acl !== undefined &&
      renderedRoute.acl !== 'guest' &&
      // Only check "isAuthenticated", it is a synchronous state, user infos will be loaded after (API call) => faster rendering
      !auth.isAuthenticated
    ) {
      if (props.notAuthorized) {
        const Component = props.notAuthorized;
        return <Component />;
      } else {
        return <div>Not authorized</div>;
      }
    } else {
      const RouteComponent = renderedRoute.component;
      return <RouteComponent {...props} route={renderedRoute} />;
    }
  } else {
    if (props.notFound) {
      const Component = props.notFound;
      return <Component />;
    } else {
      return <div>NOT FOUND</div>;
    }
  }
}
